import { Box } from "@mui/material";

const Error: React.FC = () => {
    return (
        <Box className="error cover">

        </Box>
    )
}

export default Error;